<template lang="html">
<div class="">
    <bb-bar-nav></bb-bar-nav>
    <div class="container">
        <h1>Tarieven</h1>
        <p class="vparagraph-pages">
            Baby Boom werkt met het IKG systeem. Dit betekent dat u betaalt volgens uw (gezamenlijk) inkomen.  Uw individueel tarief wordt berekend door de overheid .
            De tarieven worden ieder jaar door Kind en Gezin aangepast aan de index. Deze tarieven gelden zowel voor Belgische als Nederlandse gezinnen.Wil je vooraf weten hoeveel je ongeveer zal betalen? Doe een simulatie op mijn.kindengezin.be. Klik op ‘Attest inkomenstarief’, meld je aan met je digitale sleutel en kies voor ‘Inkomenstarief berekenen’.
        </p>
        <h2>Inbegrepen in de prijs</h2>
        <p class="vparagraph-pages">
            Inbegrepen in de prijs voor een hele opvangdag zijn: alle maaltijden, tussendoortjes en drank.
            Voor  verzorgingsproducten en afvalverwerking wordt dagelijks een extra bedrag aangerekend.
            Voor administratie en facturatie wordt maandelijks een vast bedrag aangerekend.
            Pampers en flesvoeding worden door de ouders meegebracht.
        </p>
        <h2 id="ikg">Financiële tegemoetkoming</h2>
        <p class="vparagraph-pages">
            Voor Belgische kindjes geldt dat ouders een fiscaal attest ontvangen dat recht geeft op belastingsvermindering
            Voor Nederlandse kindjes geld dat zij via de belastingdienst toeslagen recht hebben op een inkomensafhankelijke bijdrage.
        </p>
        <h1 id="#inschrijven">Inschrijven</h1>
        <p class="vparagraph-pages">
            U kunt uw kindje inschrijven na een kennismakingsgesprek en rondleiding in het kinderdagverblijf..
            Dit kan aangevraagd worden:
            Telefonisch op nummer 03.2812633
            Per email aan info@baby-boom.be
            Of via de rechtstreekse link van de kinderopvangzoeker van de Stad Antwerpen.
            Zodra de inschrijving ondertekend bij ons binnen is en de betaling van de waarborgsom (naargelang uw inkomen tussen 50 tot 250 euro) is voldaan is de inschrijving definitief.
            Bij inschrijving wordt er afgesproken op welke datum de opvang start en wordt een opvangplan vastgelegd (op welke weekdagen opvang gewenst wordt, begin- en einddatum van de opvang).
        </p>

        <!-- <h1>Contact</h1>
        <p class="vparagraph-pages">
                -
        </p> -->

        <h1 id="#kalender">Kalender</h1>
        <h2>sluitingsdagen:</h2>
        <p class="vparagraph-pages">
<table class="table">
  <thead>
    <tr>
      <th scope="col">dag</th>
      <th scope="col">datum</th>
      <th scope="col">reden</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><strong>2023</strong></td>
      <td></td>
      <td></td>
    </tr>
            <tr>
      <td>Zondag</td>
      <td>1 januari</td>
      <td>Nieuwjaar</td>
    </tr>
    <tr>
      <td>Maandag</td>
      <td>10 april</td>
      <td>Paasmaandag</td>
    </tr>
    <tr>
      <td>Maandag</td>
      <td>1 mei</td>
      <td>Feest vd arbeid</td>
    </tr>
        <tr>
      <td>Donderdag</td>
      <td>18 mei</td>
      <td>OLH Hemelvaart</td>
    </tr>
            <tr>
      <td>Vrijdag</td>
      <td>19 mei</td>
      <td>Compensatie 1/1</td>
    </tr>
    <tr>
      <td>Maandag</td>
      <td>29 mei</td>
      <td>Pinksteren</td>
    </tr>
    <tr>
      <td>Vrijdag</td>
      <td>21 juli</td>
      <td>Nationale feestdag</td>
    </tr>
    <tr>
      <td>Maandag</td>
      <td>14 augustus</td>
      <td>Collectieve verlofdag</td>
    </tr>
    <tr>
      <td>Dinsdag</td>
      <td>15 augustus</td>
      <td>OLV Hemelvaart</td>
    </tr>
    <tr>
      <td>Woensdag</td>
      <td>1 november</td>
      <td>Allerheiligen</td>
    </tr>
    <tr>
      <td>Zaterdag</td>
      <td>11 november</td>
      <td>Wapenstilstand</td>
    </tr>
    <tr>
      <td>Maandag</td>
      <td>25 december</td>
      <td>Kerstmis</td>
    </tr>
    <tr>
      <td>Dinsdag</td>
      <td>26 december</td>
      <td>Compensatie 11/11</td>
    </tr>
    <tr>
      <td>Woensdag</td>
      <td>27 december</td>
      <td>Collectieve verlofdag</td>
    </tr>
    <tr>
      <td>Donderdag</td>
      <td>28 december</td>
      <td>Collectieve verlofdag</td>
    </tr>
    <tr>
      <td>Vrijdag</td>
      <td>29 december</td>
      <td>Collectieve verlofdag</td>
    </tr>
  </tbody>
</table>
        </p>
    </div>
</div>
</template>

<script>
export default {
}
</script>

<style lang="css">


</style>
