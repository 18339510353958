
<!--
  Workhours tab
  v0.1
    [v] port static over to VUE
    [v] break homepage up in components
    [v] use good file structure
    [ ] use content given by Inge to create the final file structure

 -->

<template>
  <div id="app">
    <div id="nav">

    </div>
    <transition name="fade">
        <router-view/>
    </transition>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.vparagraph-pages{
    text-align: left;
    padding: 15px 0 ;
    font-size: 1.5em;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.fade-enter{
    opacity: 0;
}

.fade-enter-to{
    opacity: 1;
}


</style>
