<template lang="html">
  <div class="">
   <bb-bar-nav></bb-bar-nav>

      <div class="container">
          <h1>Missie</h1>
          <p class="vparagraph-pages">
                Baby Boom biedt een huiselijke, professionele opvang met enthousiaste, creatieve en professionele vaste pedagogische medewerkers.
                Geborgenheid, zelfstandigheid, veiligheid en respect voor elkaar zijn de bouwstenen van onze kwalitatieve opvang.
                Ieder kind is uniek, ze hebben elk hun eigen ritme, tempo en niveau. Wij laten een kind in zijn waarde en volgen het hierin.
                Een kind heeft voldoende vrijheid nodig om zich vrij en zelfstandig te ontwikkelen. Wij staan altijd voor ze klaar, steunen hen hierin en helpen waar nodig is.
                Iedereen wil gerespecteerd worden, ook een kind. Het zorgt ervoor dat een kind zich vrolijk voelt en goed in zijn vel zit. Dit zorgt voor een gezonde en vlotte ontwikkeling.
                Door samen te spelen en samen te delen leren kinderen om oplossingsgericht te denken. Ook leren ze voor zichzelf op te komen en te geven en nemen.
                Een kind heeft een veilige en vertrouwde omgeving nodig om zich goed te ontwikkelen. Doordat het kind vertrouwen heeft in ons en in zichzelf ontwikkelt het zich tot een blij en zelfstandig kind.
                Ieder kind heeft individuele aandacht, zorg en liefde nodig. De wetenschap dat er iemand is waar ze op kunnen vertrouwen is belangrijk.
          </p>

          <h1>Visie</h1>
            <p class="vparagraph-pages">
                Bij Baby Boom wordt vertrokken vanuit een onafhankelijke en pluralistische visie. Dit wil zeggen dat wij streven naar een diverse werking met kinderen van alle achtergronden, ongeacht politieke en religieuze overtuiging. We oordelen niet op basis van huidskleur, geaardheid of uiterlijk en hebben respect voor elkaars mening. We hechten veel waarde aan de samenwerking met de ouders.
                Baby Boom heeft bewust gekozen voor een opvang met kinderen van 0 tot 3 jaar, ingedeeld in drie horizontale leeftijdsgroepen. Daardoor hebben wij voor elk kind veel individuele aandacht en kunnen wij inspelen op de behoefte van elk kind. Ieder kind is uniek.
                Indien uw kind extra begeleiding nodig heeft, bijvoorbeeld bij een taalachterstand of met de  motoriek dan zorgen we ervoor dat uw kind optimale begeleiding krijgt, zodat de achterstand zo klein mogelijk wordt.
                Wij willen een huiselijke sfeer creëren zodat kinderen zich vertrouwd en veilig voelen, dit maakt dat de kinderen zich optimaal kunnen ontwikkelen. Continuïteit en professionaliteit zorgen voor een veilige en vertrouwde omgeving. De kinderen zien elke dag een vertrouwd gezicht doordat er twee vaste, professionele pedagogisch medewerkers aanwezig zijn per groep.
            </p>
      </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css">


</style>
