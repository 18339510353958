<template lang="html">
    <div class="row section" id="kalender">
        <div class="col-12">

              <div class="row mb-5">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-6 vblock blue"></div><div class="col-6 vblock yellow" ></div>
                  </div>
                </div>
                <div class="col-md-4" align="center"> <h1 class="vtitle">Praktisch</h1> </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-9 vblock-current red"></div><div class="col-3 vblock green" ></div>
                  </div>
                </div>
              </div>

            <div class="row">

        <!--              CONTENT HERE-->
        <p class="vparagraph">Baby Boom werkt met het
            <strong class="vunderline-blue"><router-link tag="span" to="/praktisch#ikg">IKG systeem</router-link></strong>. Dit betekent dat u betaalt volgens uw (gezamenlijk) inkomen.
            <strong class="vunderline-green"><router-link tag="span" to="/praktisch#inschrijven">Inschrijven</router-link></strong> in ons kinderdagverblijf gebeurt na een kennismakingsgesprek en rondleiding.
        Jaarlijks worden de wettelijke feestdagen en sluitingsdagen meegedeeld via onze
            <strong class="vunderline-red"><router-link tag="span" to="/praktisch#kalender">kalender</router-link></strong>.</p>
            </div>

        </div>
      </div>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
