<template lang="html">

        <div class="container-fluid">
            <header>
                  <bb-home-hero></bb-home-hero>
            </header>
        <bb-short-overons></bb-short-overons>
        <bb-short-werking></bb-short-werking>
        <bb-short-praktisch></bb-short-praktisch>
        <bb-short-fotos></bb-short-fotos>




        <!--  Kalender  -->


     <!--  CONTACT -->

      </div>


</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
