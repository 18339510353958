<template lang="html">
    <div class ="row">
      <div class = "col-12 no-side-padding" align="center">
        <div class='vhero'>
          <div class='vhero-content col-lg-6'>
              <img src="img_compressed/babyboom_logo_img-min.png" class="img-fluid">
        <!--  NAV      -->
              <div class="row mt-5">
                <div class="vnav-element col-lg-3" href="#overons">
                    <div class="vnav-underlay vnav-underlay-standard blue" ></div>
                    <h1 v-on:click="route('over-ons')" class="vnav-text vnav-text-standard ">Over Ons</h1>
                </div>
                <div class="vnav-element col-lg-3" href="#fotos">
                  <div class="vnav-underlay vnav-underlay-standard yellow" ></div>
                  <h1 v-on:click="route('werking')" class=" vnav-text vnav-text-standard ">Werking</h1>
                </div>
                <div class="vnav-element col-lg-3" href="#kalender">
                  <div class="vnav-underlay vnav-underlay-standard red" ></div>
                  <h1 v-on:click="route('praktisch')" class=" vnav-text vnav-text-standard">Praktisch</h1>
                </div>
                <div class="vnav-element col-lg-3" href="#contact">
                  <div class="vnav-underlay vnav-underlay-standard green" ></div>
                  <h1 v-on:click="vScrollTo()" class=" vnav-text vnav-text-standard ">Foto's</h1>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    methods:{
        route: function(path){
            this.$router.push({ path:path});
        },
        vScrollTo: function(){
            var fotos = document.getElementById("fotos").getBoundingClientRect();
            var options;
            window.scrollTo(0, fotos.top);
        }
    },

}
</script>

<style lang="css">
.no-side-padding{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
</style>
