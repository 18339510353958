<template lang="html">
    <div class="row section" id="overons">
      <div class="col-12 ">
          <div class="row mb-5">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-9 vblock-current blue"></div><div class="col-3 vblock yellow" ></div>
                  </div>
                </div>
                <div class="col-md-4" align="center"> <h1 class="vtitle">Over Ons</h1> </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-6 vblock red"></div><div class="col-6 vblock green" ></div>
                  </div>
                </div>
            </div>
          <div class="row vcontent">
            <!-- <div class="col-md-6 rounded vimg">
              <img src="img_compressed/Toys-min.jpg" class="img-fluid rounded">
            </div> -->
            <!-- <div class="row d-flex justify-content-center">
                <div class="col-md-3 vimg-container">
                    <img class="img-fluid rounded vimg"src="img_compressed/space-min.jpg" alt="">
                </div>
                <div class="col-md-6">
                    <p  class="vparagraph2">
                        In ons exclusief kinderdagverblijf wordt uw kindje opgenomen in een huiselijke, gezellige sfeer. Er wordt veel aandacht aan de eigen ontwikkeling van uw kind gegeven, met veel liefde en door middel van verantwoord spel en voldoende rust.
                    </p>
                </div>
            </div> -->


             <!-- <div class="col-md-6 col-centered">
                <p  class="vparagraph">
                    In ons exclusief kinderdagverblijf wordt uw kindje opgenomen in een huiselijke, gezellige sfeer. Er wordt veel aandacht aan de eigen ontwikkeling van uw kind gegeven, met veel liefde en door middel van verantwoord spel en voldoende rust.</p>
                <p class="vparagraph">
                     Het kinderdagverblijf is erkend door Kind en Gezin en wordt geleid door Inge De Facq, master in de menswetenschappen, bijgestaan door medeverantwoordelijke Valerie Van Riel en een team van gediplomeerde, ervaren kindbegeleiders.</p>
                <p class="vparagraph"> Het kinderdagverblijf werkt met het IKG-systeem (tarief volgens inkomen). </p>

                <p class="vparagraph">Leer ons beter kennen:<br><button class="btn vbtn blue btn-visie "><strong>Onze Visie ></strong></button></p>
            </div> -->

            <div class="row vcontent">
              <div class="col-md-12">
                <div class="row">

                    <div class="col-md-4">
                      <img src="img_compressed/overons1.jpg" class="img img-fluid vimg rounded-circle ">
                      <div class="vcontact-info">
                          <p class="vparagraph valign-left">
                              <i class="fas fa-clock"></i> ma-vr 7u30-18u30
                              <br><i class="fas fa-phone"></i> <span class=""><a class="vlink" href="tel:+3232812633">+32(0)3 281 26 33</a></span>
                              <br><i class="fas fa-envelope"></i>&nbsp;<span class=""><a class="vlink" href="mailto:info@baby-boom.be">info@baby-boom.be</a></span>
                              <br><i class="fas fa-map-marker-alt"></i>&nbsp;<span class=""><a class="vlink" href="https://www.google.com/maps/place/Montebellostraat+18,+2018+Antwerpen/@51.2075897,4.4057494,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3f6e40d8c8bbb:0xbb7b75b71731a420!8m2!3d51.2075897!4d4.4079381">Montebellostraat 18, 2018 Antwerpen</a></span>
                          </p>
                      </div>


                      <!-- <p class="vparagraph"> Het kinderdagverblijf werkt met het IKG-systeem (tarief volgens inkomen). </p> -->

                      <!-- <div class="row mt-3">
                         <div class="col-md-12 col-centered"> <p class="vparagraph">Leer ons beter kennen:<br><button class="btn vbtn blue btn-visie "><strong>Onze Visie ></strong></button></p></div>
                      </div> -->
                    </div>

                    <div class="col-md-4">
                      <img src="img_compressed/overons2.jpg" class="img img-fluid vimg rounded-circle">
                      <p  class="vparagraph">
                          Het kinderdagverblijf is erkend door Kind en Gezin en wordt geleid door Inge De Facq, master in de menswetenschappen, bijgestaan door een team van gediplomeerde, ervaren kindbegeleiders.
                      </p>
                    </div>

                    <div class="col-md-4">
                      <img src="img_compressed/overons3.jpg" class="img img-fluid vimg rounded-circle">
                      <p class="vparagraph">
                          In ons exclusief kinderdagverblijf wordt uw kindje opgenomen in een huiselijke, gezellige sfeer. Er wordt veel aandacht aan de eigen ontwikkeling van uw kind gegeven, met veel liefde en door middel van verantwoord spel en voldoende rust.
                       </p>
                   </div>

                </div>

              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="css">
.vimg{

}

.vimg-container{}

.btn-visie{
    margin-top: 10px;
    width: 60%;
    padding: 10px 0;
    color: #ffffff;
    font-size: 20pt;
    letter-spacing: .15em;
}

.valign-left{
    padding: auto 0;
    text-decoration: none;
}

.vcontact-info{
    width: 100%;
}
.vlink, .vlink:hover{
    color:#212529;
    text-decoration: none;
}

</style>
