<template lang="html">
    <div class="">
        <bb-bar-nav></bb-bar-nav>
        <div class="container">
            <h1 id="leefgroepen">Leefgroepen</h1>
            <p class="vparagraph-pages">
                Bij Baby Boom worden de kindjes ingedeeld in drie leefgroepen

                -	Babygroep (3-12mnd)
                -	Hier is de individuele benadering van elk kind erg belangrijk. Eigen tempo voor voeding en rust is primair.
                -	 Kruipers-Peutergroep (12–24mnd)
                -	In deze leefgroep worden vooral de motorische en “social skills” gestimuleerd.
                -	Pre-Kleuters (24 -36mnd)
                -	Hier worden vooral  ontwikkelingsgerichte activiteiten aangeboden, door Voor- Vroegschoolse veel ruimte om zich te ontwikkelen en op ontdekkingstocht te gaan. Wij zorgen voor passend spelmateriaal en uitdagende hoeken waardoor ieder kind een leerzame, gezellige en uitdagende dag beleeft.

                Door quasi dagelijks met onze peuters en pre-kleuters naar buiten te trekken komen ze al gauw in actie.
                Hierdoor wordt niet enkel hun verwondering ontluikt, maar wordt ook hun motoriek
                uitgedaagd om verder te ontwikkelen. Vertoeven in de buitenlucht heeft bovendien een positieve invloed op hun
                immuunsysteem waardoor afwezigheden ten gevolge van ziektes later in de kleuterklas tot een minimum kunnen worden herleid.
            </p>

            <h1 id="voeding">Voeding</h1>
            <p class="vparagraph-pages">
                Baby Boom zorgt voor een gezonde en gevarieerde voeding in functie van de leeftijd van het kind.
                Alle maaltijden worden dagelijks vers in onze eigen keuken bereid en zijn inbegrepen in de dagprijs,
                behalve flesvoeding voor jonge baby’s. Hiervoor brengen ouders flessenwater en melkpoeder mee.
            </p>

            <h2>Babygroep</h2>
            <p class="vparagraph-pages">
                In deze groep kiezen wij ervoor om het ritme van uw kindje zoveel mogelijk te volgen.
                De groentenpap wordt meestal in de voormiddag gegeten en de in de namiddag wordt fruitpap gegeten tenzij ouders dit anders wensen.
                Flesjes worden gegeven op tijdstippen in samenspraak met de ouders.
            </p>
            <h2>Kruipers, Peuters en Pre-Kleuters</h2>
            <p class="vparagraph-pages">
                Om 9.00 uur een stukje fruit of een cracotte en thee als drank.
                Om 11.00 uur een warme groentenmaaltijd met vlees, vis, ei  of een vegetarische vleesvervanger  gevolgd door verse soep. De kindjes drinken water bij de maaltijd.
                0m 15 uur afwisselend vers fruit, pudding of een granendessert, boterhammetjes met smeerkaas,confituur...
                Minimum tweemaal per week wordt een verse fruitmaaltijd, bestaande uit minstens vier verschillende fruitsoorten aangeboden.
                Om17.00 uur  een gezond tussendoortje (cracotte,  fruit, …) .
                Wij houden rekening met eventuele allergieen, vegetarische wensen, halal, …
                Wij verwerken zo weinig mogelijk suiker in onze maaltijden. De fruitpappen worden met rijstmeel bereid in plaats van koekjesmeel.
                Speciale voeding, dieetproducten en flesvoeding worden door de ouders meegebracht.

            </p>

            <h1 id="dagritme">Dagritme</h1>
            <p class="vparagraph-pages">
                Baby’s hebben hun eigen dagindeling. We houden ons zo veel mogelijk aan het schema van thuis.

                Voor peuters en pre-kleuters is het belangrijk dat er gewerkt wordt met een vast dagschema. Het idee hierachter is dat het de kinderen – ook de pedagogische medewerkers – houvast geeft. Door de vaste volgorde van activiteiten krijgen de kinderen een gevoel van controle over de gebeurtenissen en voelen zij zich vrij om keuzes te maken en zelf aan de slag te gaan. Natuurlijk geldt dat de kinderen centraal staan en niet het dagschema. Is een kind moe of zit het niet lekker in zijn vel dan passen we ons aan het kind aan.

                Overzicht dagindeling:
                <br><br>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">uur</th>
                      <th scope="col">activiteit</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>7:30</td>
                      <td>Onthaal, vrij spel. (7.30u – 9.00u)</td>
                    </tr>
                    <tr>
                      <td>9:00</td>
                      <td>Fruit, cracotte en thee</td>
                    </tr>
                    <tr>
                      <td>9.15</td>
                      <td>knutselen, buiten spelen, zingen, dansen, voorlezen…</td>
                    </tr>
                    <tr>
                      <td>10.30 uur</td>
                      <td>Verzorgen en verschonen</td>
                    </tr>
                    <tr>
                      <td>11.00</td>
                      <td>Samen opruimen</td>
                    </tr>
                    <tr>
                      <td>11:15</td>
                      <td>Warm middagmaal</td>
                    </tr>
                    <tr>
                      <td>11.45</td>
                      <td>Voorbereiden voor het middagdutje</td>
                    </tr>
                    <tr>
                      <td>12:00</td>
                      <td>Middagdutje (12u – 14 u)</td>
                    </tr>
                    <tr>
                      <td>14.00</td>
                      <td>verzorgen en verschonen</td>
                    </tr>
                    <tr>
                      <td>14.30</td>
                      <td>vrij spel</td>
                    </tr>
                    <tr>
                      <td>15:00</td>
                      <td>Vieruurtje</td>
                    </tr>
                    <tr>
                      <td>15:30</td>
                      <td>Activiteiten met kinderen (zie 9.15 uur)</td>
                    </tr>
                    <tr>
                      <td>16:30</td>
                      <td>verzorgen en verschonen.</td>
                    </tr>
                    <tr>
                      <td>17:00 - 18:30</td>
                      <td>afhaalmoment, feedback aan ouders,…</td>
                    </tr>
                </tbody>
            </table>
            </p>

            <h1 id="veiligheid">Veiligheid</h1>
            <p class="vparagraph-pages">
                    Om de veiligheid van de kinderen te waarborgen, hanteren wij het vierogen principe. Dit houdt in dat de pedagogisch medewerker zo min mogelijk alleen in de leefgroep staat.

                Alle leefruimtes, slaapkamers, ontvangstruimtes, inkom- en  centrale hal, tuin, evenals de inkom op de straat staan onder permanente camerabewaking.
                De beelden kunnen alleen door de leidinggevenden worden bekeken. De beelden worden ook opgenomen. De leidinggevenden kunnen deze beelden tot twee weken terug kijken . Zijn er geen verdachte situaties geweest door ouders/kinderen of pedagogisch medewerkers dan worden deze beelden definitief verwijderd. In de slaapkamers wordt eveneens permanent audtieve controle gehouden.
                De peuters en pre-kleuters (min 18mnd) slapen op ligbedjes. Er is altijd minstens één pedagogisch medewerkster bij de slapende kindjes aanwezig. In de slaapkamer van de jongste kindjes (3-18mnd) die in een spijlenbedje slapen is er permanante visuele- en auditieve controle.
                Bovendien gaat om de 10 minuten een begeleidster elk slapend kind controleren.

                Ons kinderdagverblijf is volledig conform met de eisen die door de overheid (Kind en Gezin) opgelegd worden. Wij beschikken over een geldig brandveiligheidsattest van de Antwerpse Brandweer. Alle ruimtes zijn uitgerust met rookmelders. In de centrale hal beschikken wij over een brandalarmcentrale die het hele gebouw beveiligt. Er is tevens overal noodverlichting aanwezig.
                De toegang tot het kinderdagverblijf gebeurt door een cijfercode die de ouders ontvangen zodra hun kindje instapt in opvang.

            </p>

            <h1 id="ouders">Ouders</h1>
            <p class="vparagraph-pages">
                Voor de kinderen is het van groot belang dat er goed contact is tussen hun ouders en de pedagogische medewerkers. Een prettig gevoel van de ouder heeft ook een positieve weerslag op het kind.
                De verantwoordelijkheid voor de opvoeding ligt bij de ouders. Zij delen hun verantwoordelijkheid met het kinderdagverblijf.
                De sfeer in Baby Boom is open en laagdrempelig. Wij kunnen elkaar aanspreken. Dit vinden wij belangrijk want door elkaar aan te spreken leer je elkaar goed kennen.
                De pedagogisch medewerkers schenken veel aandacht aan dit doel, omdat het de basis legt voor de verdere ontwikkeling van het kind. Dit doen zij door:
                •	Kleinschalig te werken, zo kennen alle begeleidsters alle kinderen en ouders en andersom
                •	Het kind wordt op de opvang door vaste pedagogisch medewerkers begeleid. De pedagogisch medewerksters dragen er zorg voor dat er een vertrouwensband wordt opgebouwd met het kind en de ouders door:
                    -  uw kindje persoonlijk te verwelkomen en aan te spreken bij binnenkomst
                    -  uw kindje zichzelf te laten zijn
                •	Veel aandacht voor het wennen te hebben
                •	Veel te knuffelen en ze te laten voelen dat ze welkom zijn.
                •	Oogcontact te maken.
                •	Gevoelens te benoemen.
                •	Het kind ruimte te geven om emoties te uiten.
                •	De kinderen worden uitgenodigd om deel te nemen aan activiteiten, maar zijn het niet verplicht.
                •	Door de huiselijke sfeer een thuissituatie te creëren.
                •	Een vaste structuur en dagritme te hanteren, dit geeft veiligheid en houvast.
                •	Eigen mandje beschikbaar te hebben waar persoonlijke spullen kunnen worden bewaard. Zoals reservekleding , fopspeen en knuffels.
                •	Dagelijks wordt een logboek ingevuld van de gebeurtenissen tijdens de dag zodat elke medewerker op de hoogte is van het dagverloop van uw kindje.
                Twee-maandelijks wordt uw kindje geobserveerd aan de hand van  het ZIKOVO-VOLG systeem. Dit is een individueel volgsysteem van uw kindje op
                gebied van welzijn en betrokkenheid.
                Hierdoor kunnen de pedagogisch medewerksters beter inspelen op de behoeften van je kindje als het doorschuift naar de volgende leefgroep. Door een goede vertrouwensrelatie op te bouwen, zullen kinderen zich emotioneel veilig voelen in de opvang. Ook de ouders hebben een vast aanspreekpunt voor hun kind. In de teamvergaderingen worden alle kinderen besproken.
                Op de dagen dat uw kindje komt, is er minimaal een van de twee vaste pedagogisch medewerkers van de leefgroep aanwezig. Dit is om de kinderen meer emotionele veiligheid en vertrouwen te bieden. We doen ons uiterste best om hier structureel aan te voldoen. Mocht dit onverhoopt niet lukken, door ziekte, verlof of calamiteit dan wordt een derde vaste kracht ingezet.
            </p>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="css">

</style>
