<template lang="html">
    <!--  Foto's -->
    <div class="row section" id="fotos">
        <div class="col-12">

              <div class="row mb-0">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-3 vblock blue"></div><div class="col-9 vblock yellow" ></div>
                  </div>
                </div>
                <div class="col-md-4" align="center"> <h1 class="vtitle">Foto's</h1> </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-3 vblock red"></div><div class="col-9 vblock-current green" ></div>
                  </div>
                </div>
              </div>


            <div class="container">
                <div class="row">
                    <div class="vparagraph">
                        <p class="vparagraph">
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 vimg-home ">
                        <img class="img-fluid vimg-home-blue" src="img_compressed/fotos1.jpg" alt="">
                    </div>
                    <div class="col-md-6 vimg-home">
                        <img class="img-fluid  vimg-home-yellow" src="img_compressed/fotos4.jpg" alt="">
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-6 vimg-home ">
                        <img class="img-fluid vimg-home-red" src="img_compressed/fotos3.jpg" alt="">
                    </div>
                    <div class="col-md-6 vimg-home ">
                        <img class="img-fluid vimg-home-green" src="img_compressed/fotos2.jpg" alt="">
                    </div>
                </div>
            </div>

        </div>
      </div></template>

<script>
export default {
}
</script>

<style lang="css">
    .vimg-home{
        padding:10px;
    }
    .vimg-home-blue{
        border-left: 15px solid #02027c;
    }
    .vimg-home-yellow{
        border-right: 15px solid #ffde21;
    }
    .vimg-home-red{
        border-left: 15px solid #ff0000;
    }
    .vimg-home-green{
        border-right: 15px solid #0d835a;
    }

</style>
