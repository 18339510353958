<template lang="html">
  <nav class="vnav-pages">
      <div class="row">
          <div class="col-md-3 blue navbar-link-container">
                <a v-on:click="route('over-ons')" class="navbar-link">Over Ons</a>
          </div>
          <div class="col-md-3 yellow navbar-link-container">
              <a v-on:click="route('werking')" class="navbar-link">Werking</a>
          </div>
          <div class="col-md-3 red navbar-link-container">
              <a v-on:click="route('praktisch')"  class="navbar-link">Praktisch</a>
          </div>
          <div class="col-md-3 green navbar-link-container">
              <a v-on:click="route('/')" class="navbar-link">Home</a>
          </div>
      </div>
  </nav>
</template>

<script>
export default {
    methods:{
        route: function(path){
            this.$router.push({path:path});
        }
    },
}
</script>

<style lang="css">

.navbar-link-container{
    text-align: center;
}
.navbar-link{
    font-size: 2em;
    display: inline-block;
    color: #ffffff !important;
    text-shadow: -1px 2px 5px #000;
    text-decoration: none;
}

.vnav-pages{
    margin-bottom: 40px;
}
</style>
