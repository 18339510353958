<template lang="html">
    <div class="row section" id="werking">
        <div class="col-12r">
            <div class="row mb-5">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-3 vblock blue"></div><div class="col-9 vblock-current yellow" ></div>
                </div>
              </div>
              <div class="col-md-4" align="center"> <h1 class="vtitle">Werking</h1> </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-6 vblock red"></div><div class="col-6 vblock green" ></div>
                </div>
              </div>
            </div>

            <!-- <p class="vparagraph"> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod <strong class="vunderline-blue">leefgroepen</strong> tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <strong class="vunderline-green">voeding</strong> exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <strong class="vunderline-red">Dagritme</strong> Duis aute irure dolor in reprehenderit in voluptate velit <strong class="vunderline-blue">veiligheid</strong> esse cillum dolore eu fugiat nulla pariatur. Excepteur sintreprehenderit in voluptate velit <strong class="vunderline-green">ouders</strong> occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> -->
            <p class="vparagraph">In ons kinderverblijf werken wij met drie verschillende
                <strong class="vunderline-blue"><router-link tag="span" to="/werking#leefgroepen">leefgroepen</router-link></strong>.
                Elke leefgroep heeft zijn eigen
                <strong class="vunderline-green"><router-link tag="span" to="/werking#dagritme">dagritme</router-link></strong>.
                De <strong class="vunderline-red"><router-link tag="span" to="/werking#voeding">voeding</router-link></strong> wordt elke dag vers bereid in onze eigen keuken. Wij hechten erg veel belang aan de
                <strong class="vunderline-blue"><router-link tag="span" to="/werking#veiligheid">veiligheid</router-link></strong> van onze kinderen en de samenwerking met hun
                <strong class="vunderline-green"><router-link tag="span" to="/werking#ouders">ouders</router-link></strong>.</p>
        </div>
    </div>

</template>

<script>
export default {
}
</script>

<style lang="css">
 .vunderline-red{
     text-decoration: none;
     border-bottom: 5px solid #ff0000;
 }
 .vunderline-blue{
     text-decoration: none;
     border-bottom: 5px solid  #02027c;
 }
 .vunderline-yellow{
      text-decoration: none;
      border-bottom: 5px solid #ffde21;
  }
  .vunderline-green{
       text-decoration: none;
       border-bottom: 5px solid #0d835a;
   }

</style>
