import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//  VIEWS
import App from './App.vue'
import Home from './views/Home.vue'
import OverOns from './views/OverOns.vue'
import Praktisch from './views/Praktisch.vue'
import Werking from './views/Werking.vue'
import Fotos from './views/Fotos.vue'

// COMPONENTS
import HomeHeader from './components/HomeHeader.vue'
import BarNav from './components/BarNav.vue'
import ShortOverons from './components/ShortOverons.vue'
import ShortWerking from './components/ShortWerking.vue'
import ShortPraktisch from './components/ShortPraktisch.vue'
import ShortFotos from './components/ShortFotos.vue'

Vue.component('bb-home-hero', HomeHeader);
Vue.component('bb-bar-nav', BarNav);
Vue.component('bb-short-overons', ShortOverons);
Vue.component('bb-short-werking', ShortWerking);
Vue.component('bb-short-praktisch', ShortPraktisch);
Vue.component('bb-short-fotos', ShortFotos);


const router = new VueRouter({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/over-ons',
            name: 'over-ons',
            component: OverOns,
        },
        {
            path: '/praktisch',
            name: 'praktisch',
            component: Praktisch,
        },
        {
            path: '/Werking',
            name: 'werking',
            component: Werking,
        },
        {
            path: '/fotos',
            name: 'fotos',
            component: Fotos,
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }

});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')



// CUSTOM JS SECTIONS

$('.vnav-element').css('cursor', 'pointer');
$('.vnav-element').click(function(){
  var target = $(this).attr('href');
  $(target).ScrollTo({
    duration: 1000
    });
});
//
$('.vnav-element').mouseover(function() {
   switchClass(this,'vnav-underlay-standard', 'vnav-underlay-selected' );
   switchClass(this,'vnav-text-standard', 'vnav-text-selected' );
});
$('.vnav-element').mouseout(function() {
   switchClass(this, 'vnav-underlay-selected','vnav-underlay-standard' );
    switchClass(this, 'vnav-text-selected','vnav-text-standard' );
});
function switchClass(element,remove,add){
  $( element ).children( '.' + remove ).removeClass( remove ).addClass( add );
}
