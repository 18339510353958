<template lang="html">
    <div class="wrapper">
        <bb-bar-nav></bb-bar-nav>
        <div class="container">
            <h1></h1>
        </div>

    </div>

</template>

<script>
export default {
}
</script>

<style lang="css">

</style>
